import React from 'react'
import '../css/guidence.css'

const Guidence = () => {
	return (
		<div className='guidence' dir='rtl'>
			<h1><span className='bold'>עוצמ״א</span>ערכים וצמיחה אישית</h1>
			<p className="participant">מס משתתפים מוגבל - בתיאום מראש.</p>

			<div className='alert'>
				<p className='alert-text'>
					אם את מחנכת, מורה-מקצועית או גננת, שעושה את תפקידה
					מתוך שליחות ומאמינה בלהוביל ולחנך ילדים דרך הלב,
					זהו קול הקורא לך!
				</p>
				<p className='alert-text'>
					עם נסיון שרכשתי במהלך כשלושה עשורים במערכת החינוך הפורמלי,
					הרגשתי צורך ורצון עז לבנות תוכנית,
					שתעזור למורות בתחילת דרכן
					מההבט הרגשי-חברתי.
				</p>
			</div>

			<p className='small-text small-text_sub'
				stlye={{ fontSize: '2rem' }}>לחנך ולהוביל דרך הלב</p>
			<p className='small-text'>סדנא חוויתית - שישה מפגשים בהיבט הרגשי חברתי</p>
			<p className='main-guidence-text'>
				בנושאי המפגשים מושם דגש על ההיבט הרגשי-חברתי במשולש מורה-הורה-תלמיד .
				במהלך השנים תמיד עבדתי עם הילדים בכיתה בדרך חוויתית-יצירתית.
				חשוב היה לי לגרום להם לאהוב את מה שנלמד, לזכור ולשמר חוויות.
			</p>
			<p className='main-guidence-text'>
				יוצגו שפע של רעיונות יצירתיים שלי, איך לתת לכל ילד מקום בכיתה,
				איך לממש בפועל את הפתגם - מה ששנוא עליך אל תעשה לחבריך -
				רעיונות לפעילויות עם ההורים למען תקשורת-מקרבת בין ילדיהם,
				תכנון ועריכת אסיפת ההורים הראשונה, עם הורי-הכיתה החדשה.
			</p>
			<p className='main-guidence-text'>
				רעיונות לטיפוח גבולות והרגלי-עבודה, טיפוח יחסי אמון בין מורה-תלמיד,<br /> הורה-ילד
				ועוד ועוד רעיונות, <br />שיהפכו את עבודתכן לקלילה וללא מאבקי-כוח בעיקר
				עם הילדים המיוחדים,<br /> ואיך ניתן להפוך אותם לרצויים ומשמעותיים בכיתה או בגן.
			</p>
		</div >
	)
}

export default Guidence;