import React from 'react'
import '../css/about.css'

const About = () => {
	return (
		<>
			<div className='about'>
				<div className='main-about'>
					<img style={{ aspectRatio: '1', backgroundSize: 'cover' }} src={require('../images/profile-new.jpg')} alt="" className='about-image' />
				</div>

				<div className='secondary-about'>
					<p className='about-text'>
						עופרה (אנגל) יחיאלי, ילידת הרצליה שנת 1955, מתגוררת בבאר-שבע
						אמא לשלושה ילדים וסבתא לנכדה.
						בוחרת להשתדל כל יום להיות מאושרת עם בן-זוגה ובחיים בכלל
						עם העליות והמורדות שבדרך..
						בעברה חינכה מאות תלמידים בבתי-ספר יסודי. עבודתה נעשתה תוך שליחות
						ובדרך חוויתית ויצירתית. תמיד ניסתה לגרום לתלמידיה ללמוד מתוך סקרנות,
						לקחת אחריות למעשיהם ולהאמין בעצמם. (ישנן מספר תלמידות, שכבר
						אמהות לילדים בעצמן והיא בקשר איתן עד היום).
						משנת 2006 עוסקת במודעות דרך גוף-נפש-רוח. מנחה, מדריכה ומלווה
						באופן פרטני וקבוצתי ומתאימה את עבודתה לקשת רחבה של אוכלוסיות בקהילה<br />
						עם קבוצות גיל שונות.
					</p>

					<h2 style={{ color: 'orange' }}>דברים מהלב </h2>

					<p className='aside-text'>
						תירגול היוגה, שמלווה אותי מעל עשרים שנה, חושף אותי בכל פעם
						לכוח המרפא של הנשימה, ועד כמה היא משמעותית בחיי היומיום.
						תירגול המדיטציה והמיינדפולנס, מלמדים אותי שוב ושוב לקבל וגם
						לשחרר דפוסים מן העבר, שכבר לא משרתים אותי ולהתחבר אל
						הרצונות האמיתיים שלי ומהם לצמוח.
						זו עבודה יומיומית ולא קלה. כמובן שגם אני חוטאת לפעמים, אך הודות
						לנסיון הרב בתירגול, יודעת לקחת את אותה הנשימה, שמחזירה אותי
						במהרה אל ההווה ומזכירה לעצמי, שכל מה שמגיע-מבורך,
						וכל מה שמתעכב-מדוייק.

					</p>

					<p className='aside-text'>

						בין לבין מתנדבת ב״אם-לאם״ ובעמותת ״רוח-דרומית״ בב״ש ומשתדלת לתת מקום לתחביביי השונים כמו - כתיבת ספרים (לצעירים וצעירים ברוחם), שירים ומנגינות,
						קריאה, עבודות גינון, ציור חריזת תכשיטים.

					</p>
				</div>
			</div>
		</>
	)
}

export default About;