exports.data = [
	{
		id: 0,
		title: "אודות",
	},
	{
		id: 1,
		title: 'שיעורי יוגה',
		mainText: ''
	},
	{
		id: 2,
		title: `היופי והחוכמה של ההזדקנות
קבוצת תרגול - שיח ומדיטציה`,
		list: ["נשים את הלב על...", "התמודדות עם נדודי-שינה", "הפחתת חרדות סטרס, כאבים וכעסים", "העצמת תקשורת חיובית ומקרבת",
			"קבלת השינויים בעצמינו ומסביבנו", "רלוונטיות ומשמעות לחיים", "הרחבת המודעות העצמית"],
		mainText: `
        מוזמנים מכל קשת הגילאים
        ילדים להורים מזדקנים
        נכדים לסבים
        אנשים מלווים/עובדים עם אוכלוסיה מזדקנת
        ואנשים שמתמודדים וחווים על עצמם את השינויים
        הפיזיים והנפשיים .
        
        מפגשים של אחת לשבוע, הפותחים מרחב לחקר,  
        התבוננות והתפתחות, על מנת ללמוד לקבל בזרועות פתוחות
        את השינויים, ובכלל את מה שקורה בתקופת ההזדקנות.
        דרך שיח והקשבה, דרך תירגול מדיטציית-מיינדפולנס(קשיבות),
        תרגילי נשימה, דמיון מודרך וצלילים מרפאים.`
	},
	{
		// אין צורך בניסיון קודם - באים בלב פתוח (מתחת לכותרת העמוד בצבע) *****
		id: 3,
		title: `סדנאות חוויתיות
לגוף ולנשמה`,
		subText: [
			'הסדנאות בטבע פותחות את הלב והנשימה.',
			'באווירה קלילה מפעילים את החושים ומאפשרים לעצמנו פשוט להנות -',
			'בתנועה, בכתיבה בהקשבה לצלילים ועוד.',
			"",
			'לקהל הרחב - מועדי הסדנאות ומיקומן יפורסמו במהלך כל חודש בדף הפייסבוק.',
			'מבתיאום מראש - הפעילות יכולה להתאים לקבוצת חברות, משפחה, גיבוש קבוצות-עבודה ',
			`או ציון אירוע כמו יום הולדת, יום נישואין, בת-מצווה ועוד...`
		]
	},
	{
		id: 4,
		title: "ליווי מורות בתחילת דרכן בדרך חוויתית יצירתית",
		mainText: ``
	},
	{
		id: 5,
		title: `ליווי פרטני מהיבט רגשי-חברתי
בדרך חוויתית`,
		mainText: `
הליווי מתחומי החיים - חרדה חברתית, חרדת בחינות, מוכנות רגשית/חברתית לכיתה א', פחד קהל,
מציאת בן/בת זוג, ביישנות יתר ועוד...

במיפגשים אני משלבת עבודת גוף-נפש דרך הקשבה פנימית, על-ידי 
שיחה(עבר-הווה וכוונות לעתיד) ותירגול מיינדפולנס(קשיבות), שבאמצעותם
נסללת דרך נפלאה להבין, כיצד השפעות הסביבה לאורך החיים, בעבר 
ובהווה מניעים אותנו ומשפיעים על ההתנהלות שלנו בתחומי החיים, 
עד כדי כך שאנו מסתובבים עם תחושות של אי נחת בחיינו. מפגישה לפגישה 
נבין ונגלה ביחד, שאפשר לעצור את הסבל ולשנות את דרך החשיבה, נגלה 
את הכוחות החבויים בתוכינו והם אלה שיובילו לחיים יותר שמחים ומלאים.
לאורך התהליך ניתנים שיעורי-בית, שהם חלק חשוב בהתקדמות ובשיפור.

מי שמתכוונן ורוצה באמת לעשות שינוי בחייו התוצאות לא מאחרות להגיע.
(מנסיון אישי ומדיווחים של אנשים שליוויתי).

לאחר מפגש היכרות, מתאמים לגבי המשך התהליך.`
	},
	{
		id: 6,
		title: `חוגי בית
שיח והקשבה עם קפה ומאפה`,
		list: ["נושאי המפגשים", "מערכות יחסים", "היופי והחוכמה בהזדקנות", "הצבת גבולות", "סיפורי סבתות", "הורים בתחילת דרכם", "לאחר לידה ראשונה", "התמודדות בתקופות ומצבים  מאתגרים"],
		mainText: `  המפגשים עם הקשבה לעצמנו ולשאר הנוכחים. משתפים ומעשירים
        אחד את השני/ה ומבינים שאנחנו לא לבד.
        בסיומו של כל  מפגש עורכים מדיטציית-מיינדפולנס, שאחריה מורגש
        השקט הפנימי והכוחות המחודשים להמשך.`
	},
	{
		id: 7,
		title: "ספרים",
		mainText: ``
	},
	{
		id: 8,
		title: 'הגלריה שלי - כיוצרת רב תחומית',
		mainText: ''
	}
]

