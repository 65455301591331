import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import '../css/form.css';

const initial_form = {
  fullName: '',
  email: '',
  phone: '',
  notes: ''
};

const Form = () => {
  const [formData, setFormData] = useState(initial_form);
  const [isDone, setIsDone] = useState(false);
  const [isError, setIsError] = useState(false);
  const form = useRef();

  const handleFormData = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_tmf2qh8', 'template_3xn9hsn', form.current, '48K0-pB_OGkoj8lfr')
      .then(
        (result) => {
          setFormData(() => initial_form);
          setIsDone(true);
        },
        (error) => {
          setIsError(true);
          setFormData(() => initial_form);
        }
      );

    e.target.reset();
  };

  return (
    <div className='contact-form'>
      <p>אל: <span>ofritush@gmail.com</span></p>
      <br />
      <form ref={form} onSubmit={sendEmail}>
		<label htmlFor='name'>שם</label>
				<input
					id='name'
					name='fullName'
					value={formData.fullName}
					type='text'
					placeholder=''
					onChange={handleFormData}
					required
				/>

				<label htmlFor='phone'>מס' נייד</label>
				<input
					id='phone'
					name='phone'
					value={formData.phone}
					type='phone'
					placeholder=''
					onChange={handleFormData}
					required
				/>

				<label htmlFor='email'>דואר אלקטרוני</label>
				<input
					id='email'
					name='email'
					value={formData.email}
					type='email'
					placeholder=''
					onChange={handleFormData}
					required
				/>

				<label htmlFor='notes'>הערות</label>
				<textarea
					id='notes'
					name='notes'
					value={formData.notes}
					type='textarea'
					onChange={handleFormData}
					required
				></textarea>
        <button>שלח/י</button>
      </form>
		<p>נא ליצור קשר דרך: 0545955233</p>
      {isDone && <p style={{ color: 'green' }}>האימייל נשלח בהצלחה!</p>}
      {isError && <p style={{ color: 'red' }}>האימייל לא נשלח בהצלחה!</p>}
    </div>
  );
};

export default Form;
