exports.images = [
    {
        id: 1,
        path: require('../images/gallery/img1.JPG')
    },
    {
        id: 2,
        path: require('../images/gallery/img2.JPG')
    },
    {
        id: 3,
        path: require('../images/gallery/img3.JPG')
    }

    , {
        id: 4,
        path: require('../images/gallery/img4.JPG')
    }

    , {
        id: 5,
        path: require('../images/gallery/img5.JPG')
    }

    , {
        id: 6,
        path: require('../images/gallery/img6.JPG')
    }

    , {
        id: 7,
        path: require('../images/gallery/img7.JPG')
    }

    , {
        id: 8,
        path: require('../images/gallery/img8.JPG')
    }

    , {
        id: 9,
        path: require('../images/gallery/img9.JPG')
    }

    , {
        id: 10,
        path: require('../images/gallery/img10.JPG')
    }

    , {
        id: 11,
        path: require('../images/gallery/img11.JPG')
    }

    , {
        id: 12,
        path: require('../images/gallery/img12.JPG'),
    },
    {
        id: 13,
        path: require('../images/gallery/img13.JPG')
    },
    {
        id: 14,
        path: require('../images/gallery/img14.JPG')
    },
    {
        id: 15,
        path: require('../images/gallery/img15.JPG')
    }

    , {
        id: 16,
        path: require('../images/gallery/img16.JPG')
    }

    , {
        id: 17,
        path: require('../images/gallery/img17.JPG')
    }

    , {
        id: 18,
        path: require('../images/gallery/img18.JPG')
    }

    , {
        id: 19,
        path: require('../images/gallery/img19.JPG')
    }

    , {
        id: 20,
        path: require('../images/gallery/img20.JPG')
    }

    , {
        id: 21,
        path: require('../images/gallery/img21.JPG')
    }

    , {
        id: 22,
        path: require('../images/gallery/img22.JPG')
    }

    , {
        id: 23,
        path: require('../images/gallery/img23.JPG')
    }
    , {
        id: 24,
        path: require('../images/gallery/img24.JPG')
    }

    , {
        id: 25,
        path: require('../images/gallery/img25.JPG')
    }
    , {
        id: 26,
        path: require('../images/gallery/img26.JPG')
    }

    , {
        id: 27,
        path: require('../images/gallery/img27.JPG')
    }
    , {
        id: 28,
        path: require('../images/gallery/img28.JPG')
    }

    , {
        id: 29,
        path: require('../images/gallery/img29.JPG')
    }
    , {
        id: 30,
        path: require('../images/gallery/img30.JPG')
    }

    , {
        id: 31,
        path: require('../images/gallery/img31.JPG')
    }
    , {
        id: 32,
        path: require('../images/gallery/img32.JPG')
    }
    , {
        id: 33,
        path: require('../images/gallery/img33.JPG')
    }

    , {
        id: 34,
        path: require('../images/gallery/img34.JPG')
    }
    , {
        id: 35,
        path: require('../images/gallery/img35.JPG')
    }
]